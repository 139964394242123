import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/3C/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/3C/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/3C/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/3C/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly3C2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
        src: p1,
        source: p1,
        rows: 1,
        cols: 2,
    },
    {
        src: p2,
        source:p2,
        rows: 1,
        cols: 2,
    },
    {
        src: p3,
        source: p3,
        rows: 1,
        cols: 2,
    },
    {
        src: p4,
        source: p4,
        rows: 1.2,
        cols: 2,
    },  
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Life Lessons from Ants
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 3C   Date: 16 and 17 NOVEMBER 2023

        </Typography>

        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        “Courage isn’t always a lion’s roar… It’s also silence of ant working patiently, persistently and never giving up.”
        <br/>
        </Typography>
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 3C presented their assembly on the topic, ‘Life Lessons from Ants’ on Thursday, 16 November 2023 and Friday, 17 November 2023.
        <br></br>
        The assembly began with a thought for the day which enunciated the significance of self-discipline and self-confidence that are instrumental in achieving success in life. The students enumerated various traits which ants display effortlessly that are seldom found in the intellectual beings like humans. Every trait recounted, was also well illustrated by the students through small acts.
        <br></br>
        The acts mainly stressed on the virtues of ants like resilience, self-discipline and self-confidence and their co-existence with aphids. They also empasised on shunning procrastination and social media addiction which are detrimental to our progress in life.
        <br></br>
        The life lessons one can learn from these tiny beings are numerous and the students effectively conveyed these values with their captivating presentation. 
        </Typography>
        </Box>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        “The cost of discipline is always less than the price of regret. So, self-discipline is the biggest investment for success in life.”
        <br/>
        </Typography>
        <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly3C2023;